
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { EmpreendimentoService } from "@/core/services/cadastros";
import { EmpresaService } from "@/core/services/compras/EmpresaService";
 
@Component
export default class CfgEmpreendimento extends Vue {
    filter: {
      empreendimentoId:number;
      empresaId:number;
    } = {
      empreendimentoId:0,
      empresaId:0,
    };

    valid = true; 
    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

    empreendimentos = [];
    empresas = [];
 
     
    mounted() {
      new EmpreendimentoService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
        (res) => {
          this.empreendimentos = res.data.items
        }, 
      );

      new EmpresaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
        (res) => {
          this.empresas = res.data.items
        }, 
      );
  }   

  Visualizar() {    
    let routeLink = this.$router.resolve({name: 'relDimob',
      query:{
        empresaId: this.filter.empresaId ? this.filter.empresaId.toString() : '',
        empreendimentoId: this.filter.empreendimentoId ? this.filter.empreendimentoId.toString() : ''
      }
    });

    window.open(routeLink.href, '_blank');
  }
}
